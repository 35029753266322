<div id="div-values-{{filter.id}}" class="inline-label">
  <op-date-picker (onChange)="begin = isoDateParser($event)"
                  [initialDate]="isoDateFormatter(begin)"
                  [opAutofocus]="shouldFocus"
                  required="true"
                  [id]="'values-' + filter.id + '-begin'"
                  [name]="'v[' + filter.id + ']-begin'"
                  classes="advanced-filters--date-field"
                  size="10">
  </op-date-picker>

  <span class="advanced-filters--affix" [textContent]="text.spacer">
  </span>

  <op-date-picker (onChange)="end = isoDateParser($event)"
                  [initialDate]="isoDateFormatter(end)"
                  [id]="'values-' + filter.id + '-end'"
                  [name]="'v[' + filter.id + ']-end'"
                  classes="advanced-filters--date-field"
                  size="10">
  </op-date-picker>
  <span class="advanced-filters--tooltip-trigger -multiline"
        *ngIf="isTimeZoneDifferent"
        [attr.data-tooltip]="timeZoneText">
    <op-icon icon-classes="icon icon-warning"></op-icon>
  </span>
</div>
